<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "FiveWaysToCreateNewSettingsInYourWriting",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FiveWaysToCreateNewSettingsInYourWriting">
      <SuawParagraph
        text="Creating immersive settings is a critical part of writing both fiction and nonfiction. Some of us may have a natural talent for waxing poetic about the culture, history, and geography of our literary worlds, whereas the rest of us need a little extra inspiration to produce settings that make readers forget about the real world. If you’re in the latter camp, don’t worry! Here are five ways you can create new settings in your writing."
      />
      <SuawHeading level="3" content="1. Practice Sensory Description" />
      <SuawParagraph text="When creating rich descriptions of sensory experience, the first step is to simply notice." />
      <SuawParagraph
        text="Close your eyes and take a breath. Give yourself a minute to settle. Now, open your eyes and look at your surroundings. What do you see? What are the colors of the objects surrounding you? What does it look like when the light hits that glass of water? Now, switch your focus on what you’re hearing. Are there birds chirping? Is the steady, infallible hum of the refrigerator keeping you company? How about smells? Is the aroma of leftovers wafting in from the kitchen, making you swoon?"
      />
      <SuawParagraph
        text="Now take a few minutes to jot down what you saw, heard, and smelled. If you had the chance to experience taste and touch, document those too. The idea is to practice deepening your experience of the physical world."
      />
      <SuawParagraph text="Do this exercise as many times as you need. It will help inspire you to create the same level of detail in your literary settings." />
      <SuawHeading level="3" content="2. Watch a Movie" />
      <SuawParagraph
        text='This is an easy one! <a target="_blank" href="https://www.imdb.com/chart/top/">Pick a movie</a> that is filmed in the same genre you’re writing. For example, if you’re writing a suspenseful thriller, you might consider <a target="_blank" title="https://www.imdb.com/search/title/?genres=thriller&amp;sort=user_rating,desc&amp;title_type=feature&amp;num_votes=25000,&amp;pf_rd_m=A2FGELUUNOQJNL&amp;pf_rd_p=5aab685f-35eb-40f3-95f7-c53f09d542c3&amp;pf_rd_r=PQJS5RT09JXEYDTA9TVT&amp;pf_rd_s=right-6&amp;pf_rd_t=15506&amp;pf_rd_i=top&amp;ref_=chttp_gnr_19" href="https://www.imdb.com/search/title/?genres=thriller&amp;sort=user_rating,desc&amp;title_type=feature&amp;num_votes=25000,&amp;pf_rd_m=A2FGELUUNOQJNL&amp;pf_rd_p=5aab685f-35eb-40f3-95f7-c53f09d542c3&amp;pf_rd_r=PQJS5RT09JXEYDTA9TVT&amp;pf_rd_s=right-6&amp;pf_rd_t=15506&amp;pf_rd_i=top&amp;ref_=chttp_gnr_19">one of these</a>, but instead of getting popcorn and candy, you’ll be grabbing a pen and notebook. As you’re watching the movie, make ample use of the pause button during expository scenes when you get to see wide angle perspectives on epic landscapes, or when the camera moves through an interior environment revealing details of, for example, the main character’s kitchen counter. Jot down the objects, colors, shapes, and other elements you see.'
      />
      <SuawParagraph
        text="After you’ve done this exercise, go through what you jotted down and select the elements of setting that match the goals of your writing project. For example, if you’re creating a harebrained character who never cleans the house, pick details that show the clutter of their bathroom counter or the mess on their bedroom floor. Have your “movie journal” on hand next time you sit down to write."
      />
      <SuawHeading level="3" content="3. Make a Collage or Pinterest Board" />
      <SuawParagraph
        text='Find some architecture and interior design magazines and flip through your favorite ones. If you don’t have subscriptions to these, you can find them at the supermarket, online, or even at your local library. If you get your hands on something you can cut up and turn into a collage, do it! If you’re borrowing a friend’s copy of <a target="_blank" href="https://www.architecturaldigest.com/">Architectural Digest</a> or checking out one from the library, take photos of the most compelling pages to use as inspiration.'
      />
      <SuawParagraph
        text='You can follow a similar process digitally by creating a <a target="_blank" href="https://www.pinterest.com/">Pinterest Board</a> with images of settings you find online and pinning your favorite ones. The idea is to source original visuals that you can describe in your writing to infuse your settings with lifelike detail. Have your collage or Pinterest Board in front of you during your next writing session so you can reference it while creating your settings.'
      />
      <SuawHeading level="3" content="4. Take a Trip!" />
      <SuawParagraph
        text="One of the perks of being a writer is that you get to consider all your life experiences as research! And what better way to create an immersive new setting than to visit such a place yourself? Nothing can substitute for the lived experience of seeing the sights, hearing the sounds, and tasting the flavors of a new place."
      />
      <SuawParagraph
        text="If you’re able to plan a far-flung adventure, why not make it to a destination that does double duty as a setting for your writing? It can be New Zealand, Italy, Hawaii, the Sahara Desert, New York…the list of options goes on."
      />
      <SuawParagraph
        text="But even if you’re not keen to travel far from home, it may still be possible to take a short trip to a new place in your town or a new neighborhood in your city. Use the internet to your advantage and find interesting nooks you’ve never visited to inspire your writing. Remember to bring a notebook or note-taking device so you can record your impressions as you soak in the feeling of this new area. Practicing creating sensory description (see #1 above) will prepare you that much more in capturing the unique sights, sounds, smells, tastes, and sensations of a new location—you can even directly copy and paste these into your writing."
      />
      <SuawHeading level="3" content="5. Study" />
      <SuawParagraph
        text="Finally, one of the best tools for setting-creation is the simple act of close reading. Find your favorite books and sit down to read them with paper and pen in hand. How does the author utilize detail to create immersive settings? Creating a sense of place involves sensory elements and an appropriate level of detail. How does this author balance the description of interiors, exteriors, and objects to make you sink into the narrative? What about depictions of nature? Learning from the experts is one of the best ways to move your own settings forward."
      />
      <SuawParagraph text="Feel free to mix and match the tools listed above the next time you’re ready to create vivid new places in your writing!" />
    </ArticleFrame>
  </SuawMainContent>
</template>
